import React from "react";
import IMG_CIRCLE from "../assets/img/circle.svg";
import classNames from "classnames";
import { trackEvent } from "../utility/analytics";
import { EVENT_NAME } from "../utility/app-event-logger";

class TapButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.item = null;
    this.timerId = null;
    this.counter = 0;
    this.pressHoldEvent = new CustomEvent("pressHold");
    this.pressHoldDuration = 50;
    this.pressingDown = this.pressingDown.bind(this);
    this.notPressingDown = this.notPressingDown.bind(this);
    this.timer = this.timer.bind(this);
    this.doSomething = this.doSomething.bind(this);
  }

  componentDidMount() {
    this.item = document.querySelector("#imgCircle");

    // Listening for the mouse and touch events
    this.item.addEventListener("mousedown", this.pressingDown, false);
    this.item.addEventListener("mouseup", this.notPressingDown, false);
    this.item.addEventListener("mouseleave", this.notPressingDown, false);

    this.item.addEventListener("touchstart", this.pressingDown, false);
    this.item.addEventListener("touchend", this.notPressingDown, false);

    // Listening for our custom pressHold event
    this.item.addEventListener("pressHold", this.doSomething, false);
  }

  pressingDown(e) {
    trackEvent(EVENT_NAME["GEO_STAMP_BUTTON_PRESS"]);

    // Start the timer
    if (this.props.isLatLongCaptured) {
      requestAnimationFrame(this.timer);

      this.item.classList.add("scale_circle");

      e.preventDefault();
    }
  }

  notPressingDown() {
    trackEvent(EVENT_NAME["GEO_STAMP_BUTTON_LEAVE"]);

    // Stop the timer
    cancelAnimationFrame(this.timerId);
    this.counter = 0;
    console.log("Not pressing!");
    this.item.classList.remove("scale_circle");
  }

  // Runs at 60fps when you are pressing down
  timer() {
    // console.log("Timer tick! "+this.counter);

    if (this.counter < this.pressHoldDuration) {
      this.timerId = requestAnimationFrame(this.timer);
      this.counter++;
    } else {
      this.item.dispatchEvent(this.pressHoldEvent);
    }
  }

  doSomething(e) {
    this.props.handleClick();
  }

  render() {
    let title = "PRESS AND HOLD";
    var btnClass = classNames({
      text_wrap: true,
      disabled: !this.props.isLatLongCaptured
    });
    return (
      <div>
        <div className="press_drop_wrap" id="item">
          <div className={btnClass}>
            <div className="text">{title}</div>
            <img
              data-testid="img-circle"
              id="imgCircle"
              src={IMG_CIRCLE}
              className="circle"
              alt="tap"
              title="tap"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TapButton;
