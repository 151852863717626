import React from "react";
import { withRouter } from "react-router-dom";
import IMG_THANKYOU from "../assets/img/thanks.svg";

function ThankYou(props) {
  stateFlagCheck(props);
  return (
    <>
      <canvas id="canvas"></canvas>
      <div className="row">
        <div className="container">
          <div className="col-sm-12 thanks_wrap_main">
            <div className="thanks_wrap">
              <img src={IMG_THANKYOU} alt="thanks" title="thanks" />
              <div className="thanks_text">Thank You</div>
              <div className="captured_info">
                {props.location?.state?.successFlag
                  ? `We’ve captured the drop-off location from your phone’s GPS.`
                  : `It looks like you’ve already marked the drop-off location for this job.`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const stateFlagCheck = (props) => {
  if (!props?.location.state) {
    props.history.replace({
      pathname: "/"
    });
  } else {
    startAnimation();
  }
};

const startAnimation = () => {
  // globals
  var canvas;
  var ctx;
  var W;
  var H;
  var mp = 150; //max particles
  var particles = [];
  var angle = 0;
  var confettiActive = true;
  var animationComplete = true;

  // objects

  var particleColors = {
    colorOptions: [
      "#3eb5ac",
      "#32918A",
      "#28746E",
      "#205D58",
      "#1A4A46",
      "#153B38"
    ],
    colorIndex: 0,
    colorIncrementer: 0,
    colorThreshold: 10,
    getColor: function () {
      if (this.colorIncrementer >= 10) {
        this.colorIncrementer = 0;
        this.colorIndex++;
        if (this.colorIndex >= this.colorOptions.length) {
          this.colorIndex = 0;
        }
      }
      this.colorIncrementer++;
      return this.colorOptions[this.colorIndex];
    }
  };

  function confettiParticle(color) {
    this.x = Math.random() * W; // x-coordinate
    this.y = Math.random() * H - H; //y-coordinate
    this.r = RandomFromTo(10, 30); //radius;
    this.d = Math.random() * mp + 10; //density;
    this.color = color;
    this.tilt = Math.floor(Math.random() * 10) - 10;
    this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
    this.tiltAngle = 0;

    this.draw = function () {
      ctx.beginPath();
      ctx.lineWidth = this.r / 2;
      ctx.strokeStyle = this.color;
      ctx.moveTo(this.x + this.tilt + this.r / 4, this.y);
      ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 4);
      return ctx.stroke();
    };
  }

  // window.onload = function() {
  // console.log('window.onload called');
  setTimeout(() => {
    SetGlobals();
    InitializeConfetti();

    setTimeout(() => {
      StopConfetti();
    }, 5000);

    window.onresize = function () {
      W = window.innerWidth;
      H = window.innerHeight;
      canvas.width = W;
      canvas.height = H;
    };
  }, 1000);
  // }

  function SetGlobals() {
    canvas = document.getElementById("canvas");
    ctx = canvas.getContext("2d");
    W = window.innerWidth;
    H = window.innerHeight;
    canvas.width = W;
    canvas.height = H;
  }

  function InitializeConfetti() {
    particles = [];
    animationComplete = false;
    for (var i = 0; i < mp; i++) {
      var particleColor = particleColors.getColor();
      particles.push(new confettiParticle(particleColor));
    }
    StartConfetti();
  }

  function Draw() {
    ctx.clearRect(0, 0, W, H);
    var results = [];
    for (var i = 0; i < mp; i++) {
      results.push(particles[i].draw());
    }
    Update();

    return results;
  }

  function RandomFromTo(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }

  function Update() {
    var remainingFlakes = 0;
    var particle;
    angle += 0.01;

    for (var i = 0; i < mp; i++) {
      particle = particles[i];
      if (animationComplete) return;

      if (!confettiActive && particle.y < -15) {
        particle.y = H + 100;
        continue;
      }

      stepParticle(particle, i);

      if (particle.y <= H) {
        remainingFlakes++;
      }
      CheckForReposition(particle, i);
    }

    if (remainingFlakes === 0) {
      StopConfetti();
    }
  }

  function CheckForReposition(particle, index) {
    if (
      (particle.x > W + 20 || particle.x < -20 || particle.y > H) &&
      confettiActive
    ) {
      if (index % 5 > 0 || index % 2 === 0) {
        //66.67% of the flakes
        repositionParticle(
          particle,
          Math.random() * W,
          -10,
          Math.floor(Math.random() * 10) - 10
        );
      } else {
        if (Math.sin(angle) > 0) {
          //Enter from the left
          repositionParticle(
            particle,
            -5,
            Math.random() * H,
            Math.floor(Math.random() * 10) - 10
          );
        } else {
          //Enter from the right
          repositionParticle(
            particle,
            W + 5,
            Math.random() * H,
            Math.floor(Math.random() * 10) - 10
          );
        }
      }
    }
  }

  function stepParticle(particle, particleIndex) {
    particle.tiltAngle += particle.tiltAngleIncremental;
    particle.y += (Math.cos(angle + particle.d) + 3 + particle.r / 2) / 2;
    particle.x += Math.sin(angle);
    particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 3) * 15;
  }

  function repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
    particle.x = xCoordinate;
    particle.y = yCoordinate;
    particle.tilt = tilt;
  }

  function StartConfetti() {
    W = window.innerWidth;
    H = window.innerHeight;
    canvas.width = W;
    canvas.height = H;
    (function animloop() {
      if (animationComplete) return null;
      window.requestAnimFrame(animloop);
      return Draw();
    })();
  }

  function StopConfetti() {
    animationComplete = true;
    if (ctx === undefined) return;
    ctx.clearRect(0, 0, W, H);
  }

  window.requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        return window.setTimeout(callback, 1000 / 60);
      }
    );
  })();
};

export default withRouter(ThankYou);
