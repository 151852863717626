let backendHost;
const apiVersion = "v4/";

const hostname = window && window.location && window.location.hostname;

if (/^dev/.test(hostname) || /^localhost/.test(hostname)) {
  backendHost = "https://dev02-apis.urgent.ly/";
} else if (/^qa/.test(hostname)) {
  backendHost = "https://dev01-apis.urgent.ly/";
} else {
  backendHost = "https://apis.urgent.ly/";
}

export const API_ROOT = `${backendHost}${apiVersion}`;

export const GA_EVENT_TIMEOUT = 5000;