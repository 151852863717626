import React from "react";
import { withRouter } from "react-router-dom";
import Loader from "../components/Loader";
import TapButton from "../components/TapButton";
import API from "../utility/api";
import { trackEvent, setDefaultTrackingInfo } from "../utility/analytics";
import { EVENT_NAME } from "../utility/app-event-logger";
import { GA_EVENT_TIMEOUT } from "../utility/api-config";

class GeoStamp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      vehicleInfo: "--- ---",
      // vehicleInfo: "2020 White BMW Z4",
      dropOffAddress: "4500 Richmond Road Williamsburg, VA 23185",
      licensePlate: "CGI-2078",
      location: {
        lat: null,
        long: null
      }
    };
    this.queryParams = {};
    this.submitGeoStamp = this.submitGeoStamp.bind(this);
    this.isEnvironmentProd = this.isEnvironmentProd.bind(this);
    this.onTapComplete = this.onTapComplete.bind(this);
  }

  isEnvironmentProd() {
    const hostname = window && window.location && window.location.hostname;
    let isProd = true;
    if (/^dev/.test(hostname) || /^localhost/.test(hostname)) {
      isProd = false;
    } else if (/^qa/.test(hostname)) {
      isProd = false;
    } else {
      isProd = true;
    }
    return isProd;
  }

  isLatLongCaptured() {
    return this.state.location.lat !== null && this.state.location.lat !== null;
  }

  componentDidMount() {
    console.log(this.props);
    this.queryParams = this.parseQueryString(this.props.location.search);
    console.log(this.queryParams);

    if (typeof window.gtag === "undefined" ) {
      setTimeout(() => {
        setDefaultTrackingInfo(this.queryParams);
      }, GA_EVENT_TIMEOUT);
    } else {
      setDefaultTrackingInfo(this.queryParams);
    }

    this.fetchJobData();
    this.fetchGeolocation();
  }

  fetchGeolocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("Geolocation permissions granted");
        console.log(`Latitude: ${position.coords.latitude}`);
        console.log(`Longitude: ${position.coords.longitude}`);

        trackEvent(EVENT_NAME["ALLOW_LOCATION"]);

        this.setState({
          location: {
            lat: position.coords.latitude,
            long: position.coords.longitude
          }
        });
      },
      (result) => {
        console.log(result);

        trackEvent(EVENT_NAME["DENY_LOCATION"]);

        if (result?.message && result.message.includes("denied")) {
          this.redirectToPermissonSteps();
        }
      }
    );
  }

  fetchJobData() {
    API.AUTH_TOKEN = this.queryParams.accessToken;

    this.setState({ loading: true });
    if (this.queryParams?.entityUid && this.queryParams?.jobNumber) {
      API.GET(
        `provider-driver/${this.queryParams.entityUid}/job/${this.queryParams.jobNumber}`
      )
        .then((res) => {
          this.setState({ loading: false });
          console.log("provider-driver : API : success :");
          console.log("Response :", res);

          if (res?.data?.data?.length > 0 && res.data.data[0].vehicle) {
            const vehicle = res.data.data[0].vehicle;
            this.setState({
              vehicleInfo: `${vehicle.make} ${vehicle.model}`
            });
          } else {
            let header = "";
            let body = "Oops! Something went wrong. Please try again.";

            if (
              res?.data?.errorResponseDTO?.code &&
              res?.data?.errorResponseDTO?.message
            ) {
              header = res.data.errorResponseDTO.code;
              body = res.data.errorResponseDTO.message;
            }
            const msg = {
              header: header,
              body: body,
              display: true
            };
            this.setState({ loading: false, notificationData: msg });
          }
        })
        .catch((e) => {
          console.log("provider-driver : API : fail :");
          console.error(e);
          console.log(e.toString());
          const msg = {
            header: "",
            body: "Oops! Something went wrong. Please try again.",
            display: true
          };
          this.setState({ loading: false, notificationData: msg });
        });
    }
  }

  submitGeoStamp() {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(`Latitude: ${position.coords.latitude}`);
      console.log(`Longitude: ${position.coords.longitude}`);

      const geoStampData = {
        driverUid: this.queryParams.entityUid,
        providerUid: this.queryParams.providerUid,
        channel: this.queryParams.channel,
        jobNumber: this.queryParams.jobNumber,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };

      API.AUTH_TOKEN = this.queryParams.accessToken;

      this.setState({ loading: true });
      console.log("GeoStampParams: ", geoStampData);
      API.POST("geostamp/dropoff", geoStampData)
        .then((res) => {
          console.log("geostamp/dropoff : API : success :");
          console.log("Response :", res);

          if (
            res.status === 200 &&
            res?.data?.data &&
            typeof res.data.data[0] !== "undefined"
          ) {
            trackEvent(EVENT_NAME["GEO_STAMP_COMPLETE"]);

            this.goToThankYouScreen(res.data.data[0]);
          } else {
            trackEvent(EVENT_NAME["GEO_STAMP_FAILED"]);

            let header = "";
            let body = "Oops! Something went wrong. Please try again.";

            if (
              res?.data?.errorResponseDTO?.code &&
              res?.data?.errorResponseDTO?.message
            ) {
              header = res.data.errorResponseDTO.code;
              body = res.data.errorResponseDTO.message;
            }
            const msg = {
              header: header,
              body: body,
              display: true
            };
            this.setState({ loading: false, notificationData: msg });
          }
        })
        .catch((e) => {
          console.log("geostamp/dropoff : API : fail :");
          console.error(e);
          console.log(e.toString());

          trackEvent(EVENT_NAME["GEO_STAMP_FAILED"]);

          const msg = {
            header: "",
            body: "Oops! Something went wrong. Please try again.",
            display: true
          };
          this.setState({ loading: false, notificationData: msg });
        });
    });
  }

  goToThankYouScreen = (successFlag) => {
    // Thank you screen redirection
    trackEvent("Thank You Page");

    this.setState({ loading: false }, () => {
      this.props.history.push({
        pathname: "thank-you",
        state: { successFlag }
      });
    });
  };

  redirectToPermissonSteps = () => {
    this.props.history.replace({
      pathname: "permission-steps"
    });
  };

  parseQueryString = (string) => {
    let parsed = {};
    if (string !== "") {
      string = string.substring(string.indexOf("?") + 1);
      let p1 = string.split("&");
      p1.map(function (value) {
        let params = value.split("=");
        parsed[params[0]] = params[1];
        return parsed;
      });
    }
    return parsed;
  };

  onTapComplete() {
    console.log("onTapComplete called");
    this.submitGeoStamp();
  }

  render() {
    return (
      <>
        <Loader loading={this.state.loading} />
        <div className="row">
          <div className="container">
            <div className="col-sm-12 content_details_wrap">
              <div className="content_details">
                <div className="main_title">
                  Press and hold when <br />
                  you drop-off the <br />
                  <span className="name">
                    {/*Honda Pilot*/}
                    {this.state.vehicleInfo}
                  </span>
                </div>
                {/* <div className="address_wrap">
                  <div className="sub_title">Vehicle</div>
                  <div className="address">{this.state.vehicleInfo}</div>
                  <div className="address w_normal">{this.state.licensePlate}</div>
                </div>
                <div className="address_wrap">
                  <div className="sub_title">Drop-off Location</div>
                  <div className="address">{this.state.dropOffAddress}</div>
                </div> */}
              </div>
              <TapButton
                data-testid="tap-button"
                handleClick={this.onTapComplete}
                isLatLongCaptured={this.isLatLongCaptured()}
              />
              <div className="location_wrap">
                <p className="location_txt">
                  Confirming the drop-off location from your phone’s GPS is very
                  important to our partners, and limits your liability.
                  <span className="thanks_txt">
                    Thanks for your cooperation.
                  </span>
                </p>
              </div>
            </div>

            {/* We need your permission  */}

            {/* <div className="col-sm-12 content_details_wrap deny_permission">
              <div className="content_details">
                <div className="main_title">We need your permission <br />to use your location.</div>
              </div>
              <div className="location_wrap">
                <p className="location_txt">
                  To continue, please tap the link we previously texted you. Then, tap “Allow” when the page loads.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(GeoStamp);
