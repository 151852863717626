import React from "react";
import { withRouter } from "react-router-dom";
import IMG_DOTS from "../assets/img/android-dots.svg";

// Safari 3.0+ "[object HTMLElementConstructor]"
// const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Edge 20+
// const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Android mobile Operating System
const isAndroid = /android/i.test(navigator.userAgent);

// iOS detection
const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const isiOSChrome = navigator.userAgent.match("CriOS");

console.log("isChrome: ", isChrome);
console.log("isAndroid: ", isAndroid);
console.log("isiOS: ", isiOS);

const StepsForiOSSafari = () => {
  return (
    <ol>
      <li>
        Open the <b>text message</b> again.
      </li>
      <li>
        Tap the <b>Drop-off Tracker link.</b>
      </li>
      <li>
        Tap <b>Allow</b> to use your location.
      </li>
    </ol>
  );
};

const StepsForiOSChrome = () => {
  return (
    <ol>
      <li>
        Open your iPhone <b>Settings app.</b>
      </li>
      <li>
        Find and tap <b>Chrome.</b>
      </li>
      <li>
        Tap <b>Location.</b>
      </li>
      <li>
        Tap <b>Ask Next Time.</b>
      </li>
      <li>
        Open the <b>text message</b> again.
      </li>
      <li>
        Tap the <b>Drop-off Tracker link.</b>
      </li>
      <li>
        Tap <b>Allow</b> to use your location.
      </li>
    </ol>
  );
};

const StepsForAndroidChrome = () => {
  return (
    <ol>
      <li>
        {" "}
        Open the <b>Chrome app.</b>
      </li>
      <li>
        {" "}
        Tap the <img src={IMG_DOTS} alt="dots" title="dots" /> <b>icon</b> in
        the upper right corner.
      </li>
      <li>
        {" "}
        Tap <b>Settings.</b>
      </li>
      <li>
        {" "}
        Find and tap <b>Site Settings.</b>
      </li>
      <li>
        {" "}
        Turn <b>Location On.</b>
      </li>
      <li>
        {" "}
        Open the <b>text message</b> again.
      </li>
      <li>
        {" "}
        Tap the <b>Drop-off Tracker link.</b>
      </li>
      <li>
        {" "}
        Tap <b>Allow</b> to use your location.
      </li>
    </ol>
  );
};

const PermissionSteps = (props) => {
  return (
    <>
      <div className="row">
        <div className="container">
          <div className="col-sm-12 content_details_wrap permission">
            <div className="need-permission-title">
              We need your permission <br />
              to use your location.
              <br />
            </div>
            <div className="permission-steps">
              {isiOS && !isiOSChrome ? (
                <StepsForiOSSafari />
              ) : isiOS && isiOSChrome ? (
                <StepsForiOSChrome />
              ) : (
                <StepsForAndroidChrome />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(PermissionSteps);
