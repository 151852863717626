/* Event Name */

export const EVENT_NAME = {
  ALLOW_LOCATION: "ALLOW_LOCATION",
  DENY_LOCATION: "DENY_LOCATION",
  GEO_STAMP_BUTTON_PRESS: "GEO_STAMP_BUTTON_PRESS",
  GEO_STAMP_BUTTON_LEAVE: "GEO_STAMP_BUTTON_LEAVE",
  GEO_STAMP_COMPLETE: "GEO_STAMP_COMPLETE",
  GEO_STAMP_FAILED: "GEO_STAMP_FAILED"
};
