import React from 'react'
import LOADER_IMG from '../assets/img/Spinner.gif'

const Loader = props => {
  let title = ''
  if (props.title) {
    title = props.title
  }
  return (
    <div className={`loaderbg loaderbgUS ${props.loading ? 'show' : 'hide'}`}>
      <div className="loader_content">
        <div className="loaderText">{title}</div>
        <div className={'loaderimg loaderimgUS'}>
          <img src={LOADER_IMG} title="" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Loader
