import { API_ROOT } from './api-config'
import axios from 'axios'

const instance = axios.create({
  baseURL: API_ROOT,
})
instance.defaults.timeout = 30000

function post(url, data) {
  let requestHeaders = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.AUTH_TOKEN}`,
    },
  }
  return instance.post(url, data, requestHeaders)
}

function get(url) {
  let requestHeaders = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${this.AUTH_TOKEN}`,
    },
  }
  return instance.get(url, requestHeaders)
}

// Assign object to a variable before exporting as module default to avoid eslintimport/no-anonymous-default-export warning
const API = {
  AUTH_TOKEN: '',
  POST: post,
  GET: get
}

export default API