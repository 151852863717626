import React from "react";
import { Switch, Route } from "react-router-dom";

import GeoStamp from "./views/GeoStamp";
import ThankYou from "./views/ThankYou";
import PermissionSteps from "./views/PermissionSteps";

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <GeoStamp />
      </Route>
      <Route path="/thank-you">
        <ThankYou />
      </Route>
      <Route path="/permission-steps">
        <PermissionSteps />
      </Route>
    </Switch>
  );
}

export default App;
