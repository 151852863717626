import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Router, Route } from "react-router-dom";
import { createHashHistory as createHistory } from "history";
import "./assets/css/style.css";
import App from "./App";
import { withTracker } from "./utility/analytics";
import * as serviceWorker from "./serviceWorker";

const history = createHistory();

ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      <Router history={history}>
        <Route
          component={
            process.env.REACT_APP_GTAG_ID
              ? withTracker(App, {
                  /* additional attributes */
                })
              : App
          }
        />
      </Router>
    </BrowserRouter>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
